import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { FileText, FileSearch, Sparkles, Briefcase } from "lucide-react";
import { useState, useEffect } from "react";
import { Link } from "@remix-run/react";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

export default function HeroSection() {
    return (
        <section className="min-h-[90vh] bg-white">
            <div className="max-w-[1400px] mx-auto px-6 py-20 md:py-32">
                <div className="flex flex-col lg:flex-row items-stretch gap-12 lg:gap-24">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: "easeOut" }}
                        className="flex flex-col justify-between text-center lg:text-left lg:flex-1"
                    >
                        <div>
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight max-w-[800px] mx-auto lg:mx-0 leading-[1.1] text-gray-900">
                                <span
                                    key="stand-out"
                                    className="bg-gradient-to-r from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent"
                                >
                                    Stand Out
                                </span>{" "}
                                and Land Your{" "}
                                <span
                                    key="dream-job"
                                    className="bg-gradient-to-r from-pink-600 via-purple-700 to-blue-700 bg-clip-text text-transparent"
                                >
                                    Dream Job
                                </span>
                            </h1>
                            <p className="mt-8 text-lg sm:text-xl text-gray-600 max-w-2xl mx-auto lg:mx-0 leading-relaxed">
                                Using AI, we customize your CV to showcase your strengths and match
                                every job description perfectly.
                            </p>
                        </div>
                        <div>
                            <div className="mt-12 flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                                <Button
                                    size="lg"
                                    className="bg-gradient-to-r from-pink-600 via-purple-700 to-blue-700 text-white hover:opacity-90 text-base font-medium h-12 px-8 rounded-full"
                                    asChild
                                >
                                    <Link to="/auth/signup">
                                        Start Tailoring Your CVs
                                    </Link>
                                </Button>
                                <Button
                                    size="lg"
                                    variant="outline"
                                    className="border-[1.5px] border-gray-900 text-gray-900 hover:bg-gray-50 text-base font-medium h-12 px-8 rounded-full"
                                    asChild
                                >
                                    <Link to="#how-it-works">See How It Works</Link>
                                </Button>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 40 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
                        className="w-full lg:w-[560px] xl:w-[640px] flex items-center"
                    >
                        <div className="w-full h-full aspect-video">
                            <LiteYouTubeEmbed
                                id="p4aMhMnd3ak"
                                title="Stop Rewriting Resumes: TailoredCV Does It for You! "
                                adNetwork={false}
                                noCookie={true}
                            />
                        </div>
                    </motion.div>
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4, ease: "easeOut" }}
                    className="w-full max-w-3xl mx-auto mt-24"
                >
                    <div className="rounded-2xl bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 p-[1px]">
                        <div className="rounded-2xl bg-white/95 backdrop-blur-[1px] p-8 sm:p-12">
                            <CVTransformation />
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    );
}

function CVTransformation() {
    const [currentStep, setCurrentStep] = useState(0);
    const steps = [
        { icon: FileText, label: "Import your basic CV" },
        { icon: FileSearch, label: "Add job description" },
        { icon: Sparkles, label: "AI enhancement" },
        { icon: Briefcase, label: "Get hired with tailored CV" },
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000);
        return () => clearInterval(timer);
    }, [steps.length]);

    return (
        <div className="relative">
            <div className="flex justify-between relative z-10">
                {steps.map((step, index) => (
                    <div key={step.label} className="flex flex-col items-center w-16 sm:w-28">
                        <div className="w-8 h-8 sm:w-14 sm:h-14 flex items-center justify-center">
                            <step.icon
                                className={`w-6 h-6 sm:w-10 sm:h-10 transition-colors duration-300 ${index === currentStep
                                    ? "text-gradient from-pink-600 via-purple-700 to-blue-700"
                                    : "text-gray-300"
                                    }`}
                                strokeWidth={1}
                            />
                        </div>
                        <p
                            className={`mt-1 sm:mt-3 text-[10px] sm:text-sm text-center leading-tight transition-colors duration-300 ${index === currentStep ? "text-gray-900" : "text-gray-400"
                                }`}
                        >
                            {step.label}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}
